import { Box, Card, CardMedia, Typography } from '@mui/material';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import events from './events.json';

const AnniversaryView: React.FunctionComponent = () => {
    const [imageIndices, setImageIndices] = useState<{ [key: string]: number }>({});
    const [images, setImages] = useState<string[]>([]);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndices((prev) => {
                const newIndices = { ...prev };
                events.forEach((element) => {
                    if (element.images && element.images.length > 1) {
                        newIndices[element.date] = (((prev[element.index] || 0) + 1) % element.images.length) - 1;
                    }
                });
                return newIndices;
            });
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <DefaultMargin>
            <Title>Et udpluk af højdepunkterne fra 40 års virke som landsdækkende Auktionshus i Vestjylland</Title>

            {events.map((element) => (
                <Card
                    variant="outlined"
                    style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '800px' }}
                    sx={{ p: 2, display: 'flex', flexWrap: 'wrap', zIndex: 1, mb: 2, boxShadow: 2 }}
                    key={element.index}
                    onClick={() => {
                        const images = element.images?.map((image) => `https://s3.eu-central-1.amazonaws.com/assets.holstebroauktioner.com/anniversary/fullsize/${image}`);
                        console.log('images :>> ', images);
                        setPhotoIndex(0);
                        setImages(images ?? []);
                    }}
                >
                    {element.images && (
                        <CardMedia
                            component="img"
                            sx={{
                                borderRadius: '6px',
                                width: { xs: '100%', sm: 100 },
                                height: 100,
                                objectFit: 'cover',
                            }}
                            image={`https://s3.eu-central-1.amazonaws.com/assets.holstebroauktioner.com/anniversary/${element.images[imageIndices[element.date] || 0]}`}
                            alt={element.date}
                        />
                    )}
                    <Box
                        sx={{
                            alignSelf: 'center',
                            ml: 2,
                            flex: 1,
                            minWidth: 0,
                        }}
                    >
                        <Typography variant="h6">{element.date}</Typography>
                        <Typography>{element.message}</Typography>
                    </Box>
                </Card>
            ))}
            {images.length > 0 && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={photoIndex + 1 > images.length ? undefined : images[photoIndex + 1]}
                    prevSrc={photoIndex - 1 < 0 ? undefined : images[photoIndex - 1]}
                    onCloseRequest={() => setImages([])}
                    onMovePrevRequest={() => setPhotoIndex(photoIndex - 1)}
                    onMoveNextRequest={() => setPhotoIndex(photoIndex + 1)}
                />
            )}
        </DefaultMargin>
    );
};

export default AnniversaryView;
