import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useAxios from 'axios-hooks';
import ActivityIndicator from 'common/views/activityIndicator';
import DefaultMargin from 'components/DefaultMargin';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from 'stores';
import config from 'utilities/config';
import { Post } from './models/post';
import FrontpagePost from './views/frontpagePost';
import Sidebar from './views/sidebar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        desktopSection: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'initial',
            },
        },
        mobileSection: {
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
    })
);

const Frontpage: React.FunctionComponent = observer(() => {
    const styles = useStyles();
    const [{ data, loading }] = useAxios(`${config().server.path}/news`);
    const { t } = useTranslation();
    const { auctionStore } = React.useContext(StoreContext);

    if (loading) {
        return <ActivityIndicator message="Indlæser nyheder" />;
    }

    if (!data) {
        return <Typography variant="body1">Kunne ikke hente nyheder</Typography>;
    }

    const posts = (data as [any]).map((x) => new Post(x));
    const futureAuctions = auctionStore.futureAuctions();
    let showSidebar = false;
    if (futureAuctions && futureAuctions.length > 0) {
        showSidebar = true;
    }

    return (
        <React.Fragment>
            <DefaultMargin>
                <div className={styles.mobileSection}>
                    <Typography gutterBottom variant="h5">
                        {t('frontpage_next_auctions')}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Sidebar />
                    </div>
                    <Typography style={{ marginTop: '1em' }} gutterBottom variant="h5">
                        {t('frontpage_news')}
                    </Typography>
                    {posts.map((post) => (
                        <FrontpagePost post={post} key={post.id} />
                    ))}
                </div>
                <div className={styles.desktopSection}>
                    {showSidebar && (
                        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item>
                                {posts.map((post) => (
                                    <FrontpagePost post={post} key={post.id} />
                                ))}
                            </Grid>
                            <Grid item>
                                
                                <Sidebar />
                            </Grid>
                        </Grid>
                    )}
                    {!showSidebar && (
                        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item>
                                {posts.map((post) => (
                                    <FrontpagePost post={post} key={post.id} />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </div>
            </DefaultMargin>
        </React.Fragment>
    );
});

export default Frontpage;
