import { Button, Divider, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import AuctionDto from 'models/backend/AuctionDto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';
import FutureAuctionsWidget from './futureAuctionsWidget';

const useStyles = makeStyles((theme) => ({
    widget: {
        marginBottom: theme.spacing(2),
    },
    anniversaryWidget: {
        padding: theme.spacing(1, 2, 1, 2),
        maxWidth: 400,
        cursor: 'pointer',
        marginBottom: theme.spacing(2),
    },
}));

interface SidebarProps {}

const Sidebar: React.FunctionComponent<SidebarProps> = observer((props) => {
    const styles = useStyles();
    const { auctionStore } = React.useContext(StoreContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const futureAuctions = auctionStore.futureAuctions();
    if (!futureAuctions) {
        return null;
    }

    return (
        <div>
            <Typography gutterBottom variant="h6">
                {t('frontpage_next_auctions')}
            </Typography>
            {futureAuctions.length > 0 &&
                futureAuctions.map((x: AuctionDto) => (
                    <div key={x.id} className={styles.widget}>
                        <FutureAuctionsWidget auction={x} />
                    </div>
                ))}
            <Paper className={styles.anniversaryWidget} onClick={() => navigate('/anniversary')}>
                <Typography variant="h6" style={{ marginBottom: 0, lineHeight: 1.2 }}>
                    Jubilæum
                </Typography>
                <Typography variant="subtitle1">Holstebro Auktioner fejrer i år 40 år som auktionshus. Se højdepunkterne i vores historie her.</Typography>
                <React.Fragment>
                    <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0.5rem' }}>
                        <Button variant="outlined" onClick={() => navigate(`/anniversary`)} color="primary" size="small">
                            Se højdepunkterne
                        </Button>
                    </div>
                </React.Fragment>
            </Paper>
        </div>
    );
});

export default Sidebar;
