import * as Sentry from "@sentry/react";
import buysellSource from "assets/buysell.md";
import privacyPolicyDKSource from "assets/privacy_policy_dk.md";
import termsAndConditionsSource from "assets/terms_and_conditions.md";
import termsOfSaleSource from "assets/terms_of_sale.md";
import valuationSource from "assets/valuation.md";
import Archive from "pages/archive";
import Auction from "pages/auction";
import CatalogSectionPage from "pages/catalog_section";
import ConfirmUserConfirmationCode from "pages/confirm_user_confirmation_code";
import CreateUserPage from "pages/create_user";
import ForgotPasswordPage from "pages/forgot_password";
import Frontpage from "pages/frontpage";
import Logon from "pages/logon";
import LotDetails from "pages/lot_details";
import AnniversaryView from "pages/anniversary";
import MyAuctionPurchases from "pages/my_auction_purchases";
import MyBids from "pages/my_bids";
import MyProfile from "pages/my_profile";
import MyPurchases from "pages/my_purchases";
import NewPasswordPage from "pages/new_password";
import * as React from "react";
import { Route, Routes } from "react-router";
import AboutPage from "./about";
import AcceptTerms from "./accept_terms";
import Auctions from "./auctions/index";
import ConfirmEmail from "./confirm_email";
import GenericPage from "./generic_page/index";
import Jobs from "./jobs/index";
import LogonPhaseUpdateProfile from "./update_profile";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ContentRouting: React.FunctionComponent = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<Frontpage />} />
      <Route path="/auctions" element={<Auctions />} />
      <Route path="/archive" element={<Archive />} />
      <Route
        path="/terms_conditions"
        element={<GenericPage markdownPath={termsAndConditionsSource} />}
      />
      <Route
        path="/terms_of_sale"
        element={<GenericPage markdownPath={termsOfSaleSource} />}
      />
      <Route
        path="/privacy_policy"
        element={<GenericPage markdownPath={privacyPolicyDKSource} />}
      />
      <Route
        path="/valuations"
        element={<GenericPage markdownPath={valuationSource} />}
      />
      <Route
        path="/buysell"
        element={<GenericPage markdownPath={buysellSource} />}
      />
      <Route path="/auction/:auctionId" element={<Auction />} />
      <Route
        path="/catalog/:catalogId/section/:sectionId"
        element={<CatalogSectionPage />}
      />
      <Route path="/catalog/:catalogId/lots/:lotId" element={<LotDetails />} />
      <Route path="/my_profile" element={<MyProfile />} />
      <Route path="/my_bids" element={<MyBids />} />
      <Route path="/my_purchases" element={<MyPurchases />} />
      <Route path="/my_purchases/:auctionId" element={<MyAuctionPurchases />} />
      <Route path="/logon" element={<Logon />} />
      <Route path="/logon/create_user" element={<CreateUserPage />} />
      <Route path="/logon/new_password" element={<NewPasswordPage />} />
      <Route path="/logon/forgot_password" element={<ForgotPasswordPage />} />
      <Route
        path="/create_user/confirm_code"
        element={<ConfirmUserConfirmationCode />}
      />
      <Route path="/logon/accept_terms" element={<AcceptTerms />} />
      <Route
        path="/logon/update_profile"
        element={<LogonPhaseUpdateProfile />}
      />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/profile/confirm_email" element={<ConfirmEmail />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/anniversary" element={<AnniversaryView />} />
    </SentryRoutes>
  );
};

export default ContentRouting;
