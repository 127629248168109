import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { StoreContext } from "stores";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1, 1.5),
    color: "white",
  },
}));

export interface DesktopLinksProps {
  className: string;
  showMenu: (target: HTMLElement) => void;
}

const DesktopLinks: React.FunctionComponent<DesktopLinksProps> = observer(
  (props) => {
    const navigate = useNavigate();
    const styles = useStyles();
    const { userStore } = React.useContext(StoreContext);
    const { t } = useTranslation();

    return (
      <nav className={props.className}>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/auctions"
        >
          {t("menu.auctions")}
        </Link>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/buysell"
        >
          {t("menu.buy_and_sell")}
        </Link>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/valuations"
        >
          {t("menu.valuations")}
        </Link>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/archive"
        >
          {t("menu.archive")}
        </Link>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/anniversary"
        >
          {t("menu.anniversary")}
        </Link>
        <Link
          variant="button"
          color="textPrimary"
          className={styles.link}
          component={RouterLink}
          to="/about"
        >
          {t("menu.about")}
        </Link>
        <Button
          style={{ color: "white" }}
          disableRipple
          variant="text"
          onClick={(element) => {
            if (userStore.isAuthenticated) {
              props.showMenu(element.currentTarget);
            } else {
              navigate("/logon");
            }
          }}
        >
          {userStore.isAuthenticated && userStore.profile
            ? t("menu.profile", {
                username: userStore.profile.onlineUser!.firstName,
              })
            : t("generic.logon")}
        </Button>
        {/* {userStore.isAuthenticated && (
                <Button
                    style={{ color: 'white' }}
                    disableRipple
                    variant="outlined"
                    color="default"
                    onClick={(element) => {
                        userStore.logout();
                    }}
                >
                    Log ud
                </Button>
            )} */}
      </nav>
    );
  }
);

export default DesktopLinks;
